/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DecisionStatusEnum } from "./DecisionStatusEnum";

export type Decision = {
  /**
   * Product code of a brand
   */
  product_code: string;
  decision: DecisionStatusEnum;
  /**
   * ISO 8601 timestamp of the decision
   */
  decision_time: string;
  failed_rules?: Array<string>;
  /**
   * Whether a decision is manual or automatic
   */
  decision_type: Decision.decision_type | null;
  /**
   * The id of the user that manually updated the decision
   */
  user_id?: string | null;
  /**
   * The reason the decision was manually over-ridden
   */
  override_reason?: string | null;
  /**
   * The case number of the request for information
   */
  case_reference?: string | null;
  /**
   * The group name of the product settings
   */
  settings_group_name?: string | null;
};

export namespace Decision {
  /**
   * Whether a decision is manual or automatic
   */
  export enum decision_type {
    AUTOMATIC = "AUTOMATIC",
    MANUAL = "MANUAL",
  }
}
