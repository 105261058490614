/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BasicErrorModel = {
  /**
   * The error code, will be `null` if the request was successful.
   */
  errorCode?: string;
  /**
   * Details of the particular error, will be `null` if the request was successful.
   */
  errorDetails?: string;
  /**
   * Description of the error code, will be `null` if the request was successful.
   */
  errorString?: string;
  /**
   * The status of the response.
   */
  status?: BasicErrorModel.status;
};

export namespace BasicErrorModel {
  /**
   * The status of the response.
   */
  export enum status {
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
  }
}
