/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AttachmentUrl } from "./AttachmentUrl";

export type MoreInformationRequestedCase = {
  case_reference?: string;
  customer_id?: string;
  information_requested?: string;
  information_requested_datetime?: string;
  information_provided?: string | null;
  information_provided_datetime?: string | null;
  product_codes?: Array<string>;
  attachments?: Array<string> | null;
  request_attachments?: Array<string> | null;
  attachment_urls?: Array<AttachmentUrl> | null;
  request_attachment_urls?: Array<AttachmentUrl> | null;
  status?: MoreInformationRequestedCase.status;
};

export namespace MoreInformationRequestedCase {
  export enum status {
    OPEN = "OPEN",
    CLOSED = "CLOSED",
  }
}
